












import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import { extractComponents } from '@vsf-enterprise/amplience';
import { componentResolver } from '@amplience/helpers/componentResolver';

export default defineComponent({
  name: 'RenderContent',
  props: {
    content: {
      type: [Array, Object],
      default: () => [],
    },
    skeleton: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props) {
    return {
      components: computed(() => (
        extractComponents(
          componentResolver(props.content, props.skeleton)
        )
      )),
    };
  },
});
