

















































import { computed, defineComponent, useContext, ref } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { storeToRefs } from 'pinia';
import { useUiState } from '~/composables';
import { useAuth } from '@vaimo-azure-auth/composables/useAuth';
import { useMyAccountNavigation } from '@theme/modules/customer/stores/myAccountNavigation';
import Modal from '@theme/components/organisms/Modal.vue';
import { isDualRunning } from '@theme/helpers/dualRunning';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useHybrisUser } from '~/modules/customer/composables/useUser/useHybrisUser';

export default defineComponent({
  name: 'AccountSwitcherModal',
  components: {
    Modal,
    SfButton,
  },
  setup() {
    const { logout } = useAuth();

    const {
      isAccountSwitcherModalOpen: isOpened,
      closeAccountSwitcherModal: closeModal,
    } = useUiState();

    const navigation = useMyAccountNavigation();
    const customerStore = useCustomerStore();

    const { filteredQuickAccessLinks } = storeToRefs(navigation);
    const { sapLinkedAccounts, fullName, sapUniqueID } = storeToRefs(customerStore);

    const errorHybris = ref({ load: false });
    const { switchAccount, setDefaultAccount } = useHybrisUser(errorHybris);

    const { app } = useContext();

    const handleLogout = async () => {
      await logout();
      closeModal();
    }

    return {
      isOpened,
      // @TODO Provide isEmpty and hasLoaded from the cellar_summary
      filteredLinks: computed(() => filteredQuickAccessLinks.value(
        false,
        true,
        isDualRunning(app.$cookies)
      )),
      closeModal,
      handleLogout,
      sapLinkedAccounts,
      fullName,
      sapUniqueID,
      switchAccount,
      setDefaultAccount,
    }
  }
})
