import { useCustomerStore } from '~/modules/customer/stores/customer';

export default ({ app, redirect }) => {
  const customerStore = useCustomerStore();

  app.router.afterEach((to) => {
    if (customerStore.shouldResetPassword
      && customerStore.isLoggedIn
      && to.name !== 'customer-change-password___default'
    ) {
      redirect('/customer/my-profile/change-password', {
        reason: 'security',
      });
    }
  });
};
