import { Middleware } from '@nuxt/types';
import { isDualRunning } from '@theme/helpers/dualRunning';

const middleware: Middleware = (context) => {
  if (isDualRunning(context.app.$cookies)) {
    if (process.client) {
      window.location.replace('/my-account/payment-details');
    } else {
      context.redirect('/my-account/payment-details');
    }
  }
};

export default middleware;
