import { ref, useContext, useRouter } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { LinkedAccount, useCustomerStore } from '~/modules/customer/stores/customer';
import { useHybrisCart } from '~/modules/checkout/composables/useCart/useHybrisCart';
import { useUiState, useUiNotification } from '~/composables';
import { isSemiSession } from '@theme/helpers/dualRunning';

export const useHybrisUser = (error) => {
  const loading = ref(false);

  const router = useRouter();
  const { app, redirect, route } = useContext();
  const customerStore = useCustomerStore();
  const { load: loadHybrisCart } = useHybrisCart(loading, error);

  const { closeAccountSwitcherModal } = useUiState();
  const { send: sendNotification } = useUiNotification();

  const saveUser = (user, accounts) => {
    let sapDefaultId = user.customerAccount.cprAccountId;

    const linkedAccounts: LinkedAccount[] = accounts.map(
      account => {
        if (account.isDefaultAccount) {
          sapDefaultId = account.accountId;
        }

        return {
          name: account.accountName,
          sap_unique_id: account.accountId,
          is_default: account.isDefaultAccount,
          is_active: user.customerAccount.cprAccountId === account.accountId,
        }
      }
    );

    customerStore.user = {
      firstname: user.customerData.firstName,
      lastname: user.customerData.lastName,
      account_name: user.customerAccount.bbrAccountName,
      debit_or_credit: user.debitOrCredit || '',
      store_credit_balance: user.storeCreditBalance || '',
      has_berry_account: !!user.hasBerryAccount,
      is_from_backed: !!user.isFromBacked,
      hide_cp_section: !!user.hideCPSection,
      cellar_account_id: user.customerAccount.cellarAccountId,
      cellar_plan_monthly_payment: user.customerAccount.cellarPlanMonthlyPayment,
      algolia_user_public_key: user.algolia_user_public_key || '',
      email: user.customerData.uid,
      prefix: user.customerData.titleCode,
      sap_unique_id: user.customerAccount.cprAccountId,
      sap_default_id: sapDefaultId,
      linked_accounts: linkedAccounts,
      bbx_buyer: user.bbrAccountData.bbxBidder,
      bbx_seller: user.bbrAccountData.bbxSeller,
      wishlist: {},
      wishlists: [],
      reviews: { items: [], page_info: {} },
      custom_attributes: {},
      allow_remote_shopping_assistance: false,
      commission_rate: user.bbrAccountData.commissionRate,
    };
  };

  const fetchAccountData = async () => {
    const [response, { data: accountsListData }] = await Promise.all([
      app.context.$vsf.$hybris.api.getAccount(),
      app.context.$vsf.$hybris.api.getAccountsList(),
    ]);

    Logger.debug('[Hybris] useHybrisUser.fetchAccountData profile:', response);

    // Redirect to change password page if user is required to change password
    if (response?.status === 302 && response.redirect.includes('change-password')) {
      customerStore.setShouldResetPassword(true);

      if (route.value.name !== 'customer-change-password___default') {
        redirect(response.redirect);
      }
    } else {
      customerStore.setShouldResetPassword(false);
    }

    if (response?.data?.customerAccount) {
      saveUser(response?.data, accountsListData);
    }
  };

  /**
   * Loads Hybris cart.
   * If cart is not anonymous, loads user profile too.
   * Sets both responses to its stores.
   */
  const loadCartAndUser = async (isLoggedIn = false) => {
    try {
      loading.value = true;

      try {
        Logger.debug('[Hybris] useHybrisUser.loadCartAndUser');

        if (isLoggedIn) {
          loadHybrisCart();
        } else {
          const response = await loadHybrisCart();

          if (response.cartData.user.uid === 'anonymous') {
            return;
          }
        }

        await fetchAccountData();
      } catch (err) {
        Logger.error('[Hybris] useHybrisUser.loadCartAndUser', err);
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useHybrisUser/loadCartAndUser', err);
    } finally {
      loading.value = false;
    }
  };

  /**
   * Loads Hybris user profile.
   */
  const loadUser = async () => {
    try {
      loading.value = true;

      try {
        Logger.debug('[Hybris] useHybrisUser.loadUser');

        await fetchAccountData();
      } catch (err) {
        Logger.error('[Hybris] useHybrisUser.load', err);
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useHybrisUser/loadUser', err);
    } finally {
      loading.value = false;
    }
  };

  const loadUserWithSessionCheck = async () => {
    await loadUser();

    if (isSemiSession(app.$cookies)) {
      redirect('/login', {
        reason: 'inactivity',
        referrerName: route.value.name.replace(/___default$/, ''),
      });
    }
  };

  /**
   * Switch between accounts under the same user login
   */
  const switchAccount = async ({ accountId, isDefault }) => {
    try {
      loading.value = true;

      try {
        Logger.debug('[Hybris] useHybrisUser.switchAccount');

        const { data } = await app.context.$vsf.$hybris.api.switchAccount({
          bbrAccountId: accountId,
          isDefaultBBRAccount: isDefault,
        });

        Logger.debug('[Hybris] account:', { data });

        closeAccountSwitcherModal();

        // Reload current page
        router.go(0);

        sendNotification({
          id: Symbol('account_switched'),
          // eslint-disable-next-line max-len
          message: app.i18n.t('You have successfully switched your account. Please wait for the page to refresh.') as string,
          type: 'success',
          icon: 'check',
          persist: false,
          title: app.i18n.t('Account switched') as string,
        });
      } catch (err) {
        Logger.error('[Hybris] useHybrisUser.switchAccount', err);
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useHybrisUser/switchAccount', err);
    } finally {
      loading.value = false;
    }
  };

  /**
   * Set an account as default
   */
  const setDefaultAccount = async ({ accountId }) => {
    try {
      loading.value = true;

      try {
        Logger.debug('[Hybris] useHybrisUser.setDefaultAccount');

        await app.context.$vsf.$hybris.api.setDefaultAccount({
          bbrAccountId: accountId,
        });

        loadUser();
      } catch (err) {
        Logger.error('[Hybris] useHybrisUser.setDefaultAccount', err);
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useHybrisUser/setDefaultAccount', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    loadCartAndUser,
    loadUser,
    loadUserWithSessionCheck,
    switchAccount,
    setDefaultAccount,
    loading,
  };
};
