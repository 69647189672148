










































import { defineComponent, ref, PropType } from '@nuxtjs/composition-api';
import { SfOverlay } from '@storefront-ui/vue';
import { ImageInterface } from '@amplience/types';
import { NavigationInterface } from '@amplience/types';
import AccountModal from '@amplience/components/molecules/header/AccountModal.vue';
import Cart from '@amplience/components/molecules/header/Cart.vue';
import CountrySelector from '@amplience/components/molecules/header/CountrySelector.vue';
import Wishlist from '@amplience/components/molecules/header/Wishlist.vue';
import WarrantyLogos from '@amplience/components/molecules/header/WarrantyLogos.vue';
import Logo from '@amplience/components/molecules/header/Logo.vue';
import Search from '@amplience/components/molecules/header/Search.vue';

export default defineComponent({
  /* eslint-disable vue/require-default-prop */
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Header',
  components: {
    AccountModal,
    Cart,
    CountrySelector,
    Wishlist,
    WarrantyLogos,
    SfOverlay,
    Logo,
    Search,
  },
  props: {
    warrantLeft: {
      type: Object as PropType<ImageInterface>,
    },
    warrantRight: {
      type: Object as PropType<ImageInterface>,
    },
    logo: {
      type: Object as PropType<ImageInterface>,
    },
    navigation: {
      type: Object as PropType<NavigationInterface>,
    },
  },
  setup() {
    const isNavVisible = ref<boolean>(false);

    const handleNav = (): void => {
      isNavVisible.value = !isNavVisible.value;
      document.body.classList.toggle('nav-active', isNavVisible.value);
    };

    return {
      handleNav,
      isNavVisible,
    };
  },
});
