/**
 * Transfoms cookie string from the request object into a keyed cookie array
 */
module.exports.getCookies = (req) => {
  const cookies = {};
  const cookiesArray = req?.headers?.cookie?.split(';') || [];

  cookiesArray.forEach((cookie) => {
    const [key, value] = cookie.trim().split(/=(.+)/);

    cookies[key] = value;
  });

  return cookies;
};

module.exports.getResponseCookie = (headers, name) => {
  const cookies = headers['set-cookie'];

  if (!cookies) {
    return null;
  }

  // Only one cookie string is provided
  if (!Array.isArray(cookies)) {
    if (cookies.includes(name)) {
      return cookies.replace(`${name}=`, '').split('; ')[0];
    }
  }

  const cookieString = cookies.find(cookieString => cookieString.includes(name));

  return cookieString.replace(`${name}=`, '').split('; ')[0];
}
