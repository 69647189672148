import { Middleware } from '@nuxt/types';
import { isDualRunning, isFullyLoggedIn, isSemiSession } from '~/bbrTheme/helpers/dualRunning';

const middleware : Middleware = (context) => {
  if (isDualRunning(context.app.$cookies)) {
    if (isSemiSession(context.app.$cookies)) {
      context.redirect('/login', {
        reason: 'inactivity',
        referrerName: context.route.name.replace(/___default$/, ''),
      });
    } else if (!isFullyLoggedIn(context.app.$cookies)) {
      context.redirect('/login', {
        referrerName: context.route.name.replace(/___default$/, ''),
      });
    }
  } else if (!context.app.$vsf.$magento.config.state.getCustomerToken()) {
    context.redirect('/auth/redirect', {
      type: 'login',
    });
  }
};

export default middleware;
