

















import { computed, defineComponent, onMounted, ref, useContext, useRoute, PropType } from '@nuxtjs/composition-api';
import { SfBreadcrumbs } from '@storefront-ui/vue';
import {
  BreadcrumbInterface,
  getDynamicBreadcrumbs,
} from '@amplience/helpers/breadcrumbsResolver';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { getJsonLdBreadcrumbs } from '~/helpers/jsonld';
import type { ItemList, WithContext } from 'schema-dts';

export default defineComponent({
  name: 'Breadcrumbs',
  components: {
    SkeletonLoader,
    SfBreadcrumbs,
  },
  props: {
    breadcrumbs: {
      type: Array as PropType<BreadcrumbInterface[]>,
      default: () => []
    },
    titles: {
      type: Array,
      default: () => []
    },
  },
  setup(props) {
    const { $config: { baseURL } } = useContext();
    const route = useRoute();
    const isLoading = ref<boolean>(true);

    const items = computed((): BreadcrumbInterface[] => {
      const breadcrumbs = props.breadcrumbs;
      if (breadcrumbs && breadcrumbs.length) return breadcrumbs;

      return getDynamicBreadcrumbs(route.value?.path, props.titles as any);
    });

    onMounted(() => {
      isLoading.value = false;
    })

    return {
      route,
      items,
      isLoading,
      baseURL,
    };
  },
  jsonld(): WithContext<ItemList> {
    return getJsonLdBreadcrumbs(this.items, this.route.path, this.baseURL);
  },
});
