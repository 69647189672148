
























import {
  defineComponent,
  useRoute,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  useFetch,
} from '@nuxtjs/composition-api';
import AppHeader from '@theme/components/AppHeader.vue';
import AppFooter from '@theme/components/AppFooter.vue';
import AssistedShopperHeader from '~/modules/assisted-shopper/components/AssistedShopperHeader.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import PreviewMessage from '~/modules/amplience/components/molecules/PreviewMessage.vue';
import ScrollToTop from '@theme/components/ScrollToTop.vue'
import QuickAccessModal from '@vaimo-azure-auth/components/header/QuickAccessModal.vue';
import AccountSwitcherModal from '@vaimo-azure-auth/components/header/AccountSwitcherModal.vue';
import { useCellar } from '@cellar-services/composables/useCellar';
import { useCellarUiStore } from '@cellar-services/stores/cellar-ui';
import { useUser } from '~/modules/customer/composables/useUser';
import { useContent } from '@amplience/composables/useContent';
import { getJsonLdOrganization, getJsonLdWebSite } from '~/helpers/jsonld';
import type { JsonLdSchema } from '@amplience/types';
import type { WithContext, Organization, WebSite } from 'schema-dts';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppFooter,
    AssistedShopperHeader,
    PreviewMessage,
    IconSprite,
    ScrollToTop,
    QuickAccessModal,
    AccountSwitcherModal,
    NotificationGroup: () => import('@theme/components/NotificationGroup.vue'),
  },
  transition: 'fade',
  setup() {
    const route = useRoute();
    const { search, data } = useContent();

    // Cellar layout is a special layout for the cellar pages
    // - '/cellar/overview'
    // - '/cellar/items'
    // - '/cellar/valuations'
    // - '/bbx/overview'
    // - '/bbx/listings'
    // - '/bbx/bids-received'
    // - '/bbx/bids-placed'
    // - '/bbx/alerts'
    const isCellarLayout = computed(() => route.value.meta?.cellarLayout || false);

    const { load: loadCustomer } = useUser();
    const { refreshCellar } = useCellar();
    const { setIsOpenedFromHistory } = useCellarUiStore();

    const handlePopstate = (_event: PopStateEvent) => {
      setIsOpenedFromHistory(true);
    };

    useFetch(async () => {
      await search('general-json-ld', 'url');
    });

    onMounted(async () => {
      window.addEventListener('popstate', handlePopstate);

      await loadCustomer();

      refreshCellar();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('popstate', handlePopstate);
    });

    watch(route, () => {
      if (process.client) {
        document.body.setAttribute('tabindex', '-1');
        document.body.focus();
        document.body.removeAttribute('tabindex');
        setIsOpenedFromHistory(false);
      }
    });

    return {
      data,
      route,
      isCellarLayout,
    };
  },
  jsonld(): (WithContext<Organization> | WithContext<WebSite>)[] {
    if (!this.data) {
      return null;
    }

    return [
      getJsonLdWebSite(this.data as JsonLdSchema),
      getJsonLdOrganization(this.data as JsonLdSchema),
    ];
  },
});
