import { defineStore } from 'pinia';
import { LinkItemsInterface, LinksInterface, QuickAccessLink, QuickLink } from '@cellar-services/types';

export interface AccountBreadcrumbsInterface {
  breadcrumbs: AccountBreadcrumb[];
}

export interface AccountBreadcrumb {
  text: string;
  link: string;
}

export const navigationLinks: LinkItemsInterface = {
  'my-account': {
    key: 'my-account',
    route: 'customer-my-account',
    label: 'Account',
    sortOrder: 0,
  },
  'my-profile': {
    key: 'my-profile',
    route: 'customer-my-profile',
    label: 'Profile',
    sortOrder: 10,
  },
  'my-address-book': {
    key: 'my-address-book',
    route: 'customer-my-address-book',
    label: 'Address book',
    sortOrder: 20,
  },
  'manage-payments': {
    key: 'manage-payments',
    route: 'customer-manage-payments',
    label: 'Manage-payments',
    sortOrder: 30,
  },
  'order-history': {
    key: 'order-history',
    route: 'customer-order-history',
    label: 'Orders, payments & statements',
    sortOrder: 40,
  },
  'interests': {
    key: 'interests',
    route: 'customer-interests',
    label: 'Interests',
    sortOrder: 50,
  },
  'communication-preferences': {
    key: 'communication-preferences',
    route: 'customer-communication-preferences',
    label: 'Communication preferences',
    sortOrder: 60,
  },
};

// All the links and quick links that must be enabled in Adobe Commerce
// Add new ones accordingly.
export type EnabledInACLinkKey = 'my-wishlist' | 'my-store-credit';
export type EnabledInACQuickLink = 'customer-my-wishlist';

const enabledInACLinks: LinkItemsInterface = {
  'my-wishlist': {
    key: 'my-wishlist',
    route: 'customer-my-wishlist',
    label: 'Wishlist',
    sortOrder: 45,
  },
  'my-store-credit': {
    key: 'my-store-credit',
    route: 'customer-my-store-credit',
    label: 'Store credit',
    sortOrder: 70,
  }
};

const enabledInAcQuickLinks: QuickLink[] = [
  {
    title: 'Wishlist',
    link: 'customer-my-wishlist',
    icon: 'account-heart',
    sortOrder: 40,
  }
];

// Filter links by 'key'
const addACEnabledLinks = (acEnabledLinks: EnabledInACLinkKey[]): LinkItemsInterface => {
  const result: LinkItemsInterface = {};

  if (acEnabledLinks.length > 0) {
    Object.values(enabledInACLinks)
      .filter(link => acEnabledLinks.includes(link.key as EnabledInACLinkKey))
      .forEach(link => {
        result[link.key] = link;
      });
  }

  return result;
};

// Filter quick links by 'link'
const addACEnabledQuickLinks = (acEnabledQuickLinks: EnabledInACQuickLink[]): QuickLink[] => {
  if (acEnabledQuickLinks.length === 0) {
    return [];
  }

  return Object.values(enabledInAcQuickLinks)
    .filter(link => acEnabledQuickLinks.includes(link.link as EnabledInACQuickLink));
};

export const quickLinks: QuickLink[] = [
  {
    title: 'Profile',
    link: 'customer-my-profile',
    icon: 'account-user',
    sortOrder: 0,
  },
  {
    title: 'Address book',
    link: 'customer-my-address-book',
    icon: 'account-book-open',
    sortOrder: 10,
  },
  {
    title: 'Manage payments',
    link: 'customer-manage-payments',
    icon: 'account-credit-card',
    sortOrder: 20,
  },
  {
    title: 'Orders, payments & statements',
    link: 'customer-order-history',
    icon: 'account-file',
    sortOrder: 30,
  },
];

const quickAccessLinks: QuickAccessLink[] = [
  {
    key: 'account',
    route: 'customer-my-account',
    label: 'My account',
  },
  {
    key: 'cellar',
    route: 'cellar-overview',
    label: 'My cellar',
  },
  {
    key: 'bbx',
    route: 'bbx-overview',
    label: 'My BBX',
  },
];

export const useMyAccountNavigation = defineStore('myAccountBreadcrumbs', {
  state: () => <AccountBreadcrumbsInterface>({
    breadcrumbs: [],
  }),
  getters: {
    // Remove cellar link only if data has loaded and is empty
    filteredQuickAccessLinks(): Function {
      return (isEmpty: boolean, hasLoaded: boolean, isDualRunningEnabled?: boolean): QuickAccessLink[] => {
        if (isDualRunningEnabled) {
          return quickAccessLinks;
        }

        return hasLoaded && isEmpty
          ? quickAccessLinks.filter(link => link.key !== 'cellar')
          : quickAccessLinks;
      }
    },
    filteredNavigationLinks(): Function {
      return (acEnabledLinks: EnabledInACLinkKey[]): LinksInterface[] => {
        const links: LinkItemsInterface = {
          ...navigationLinks,
          ...addACEnabledLinks(acEnabledLinks),
        };

        const sortedLinks: LinkItemsInterface = Object.values(links)
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .reduce((acc, item) => {
            acc[item.key] = item;

            return acc;
          }, {});

        return [{
          title: 'My account',
          items: sortedLinks,
        }];
      }
    },
    filteredQuickLinks(): Function {
      return (acEnabledQuickLinks: EnabledInACQuickLink[]): QuickLink[] => {
        return [
          ...quickLinks,
          ...(addACEnabledQuickLinks(acEnabledQuickLinks)),
        ].sort((a, b) => a.sortOrder - b.sortOrder);
      }
    }
  },
  actions: {
    setBreadcrumbs(breadcrumbs: AccountBreadcrumb[]) {
      this.breadcrumbs = breadcrumbs;
    },
  },
});
