import { defineStore } from 'pinia';
import type { Customer } from '~/modules/GraphQL/types';

export interface BBRCustomerInterface extends Customer {
  custom_attributes: any;
  sap_unique_id: string;
  sap_default_id: string;
  algolia_user_public_key?: string;
  linked_accounts: LinkedAccount[];
  bbx_buyer: boolean;
  bbx_seller: boolean;
  commission_rate: string | null;
  account_name: string;
  debit_or_credit: string;
  store_credit_balance: string;
  has_berry_account: boolean;
  is_from_backed: boolean;
  hide_cp_section: boolean;
  cellar_account_id?: string;
  cellar_plan_monthly_payment?: number;
}

export interface LinkedAccount {
  name: string;
  sap_unique_id: string;
  is_default: boolean;
  is_active: boolean;
}

interface CustomerState {
  user: BBRCustomerInterface | null;
  isLoggedIn: boolean;
  shouldResetPassword: boolean;
}

export const prefixes = ['Mr', 'Mrs', 'Ms', 'Miss'];

export const useCustomerStore = defineStore('customer', {
  state: (): CustomerState => ({
    user: null,
    isLoggedIn: false,
    shouldResetPassword: false,
  }),
  getters: {
    firstName: (state) => state.user ? state.user.firstname : '',
    lastName: (state) => state.user ? state.user.lastname : '',
    fullName: (state) => state.user ? `${state.user.firstname} ${state.user.lastname}` : '',
    accountName: (state) => state.user ? state.user.account_name : '',
    hasBerryAccount: (state) => state.user?.has_berry_account ?? false,
    isFromBacked: (state) => state.user?.is_from_backed ?? false,
    storeCreditBalance: (state) => state.user?.store_credit_balance ?? '',
    debitOrCredit: (state) => state.user?.debit_or_credit ?? '',
    hideCPSection: (state) => state.user?.hide_cp_section ?? false,
    cellarAccountId: (state) => state.user?.cellar_account_id ?? '',
    cellarPlanMonthlyPayment: (state) => state.user?.cellar_plan_monthly_payment ?? 0,
    email: (state) => state.user ? state.user.email : '',
    prefix: (state) => state.user ? state.user.prefix : '',
    // Unique account ID of the context, may not be the default account ID of the user
    sapUniqueID: (state) => {
      if (state.user) {
        return state.user?.sap_unique_id;
      }

      return '';
    },
    // Default account ID of the user, may be the unique account ID of the context
    sapDefaultID: (state) => {
      if (state.user) {
        return state.user?.sap_default_id;
      }

      return '';
    },
    // All linked accounts of the user, will include default ID and unique ID
    sapLinkedAccounts: (state) => {
      if (state.user) {
        return state.user?.linked_accounts;
      }

      return [];
    },
    isBidder: (state) => !!state?.user?.bbx_buyer,
    isSeller: (state) => !!state?.user?.bbx_seller,
  },
  actions: {
    setIsLoggedIn(value: boolean) {
      this.isLoggedIn = value;
    },
    setShouldResetPassword(value: boolean) {
      this.shouldResetPassword = value;
    },
  },
});
