import { Plugin } from '@nuxt/types';
import { AlgoliaSearchModuleOptions, AlgoliaDemoCategoryUid } from './types';
import algoliasearch from 'algoliasearch';
import type { SearchClient } from 'algoliasearch';

declare module 'vue/types/vue' {
  interface Vue {
    $algoliaSearch: {
      enabled: boolean;
      createClient(apiKey: string): SearchClient;
      searchClient: SearchClient;
      indexName: string;
      indexNameBiddable: string;
      demoCategoryUid: AlgoliaDemoCategoryUid;
    };
  }
}

declare module '@nuxt/types' {
  interface Context {
    $algoliaSearch: {
      enabled: boolean;
      createClient(apiKey: string): SearchClient;
      searchClient: SearchClient;
      indexName: string;
      indexNameBiddable: string;
      demoCategoryUid: AlgoliaDemoCategoryUid;
    };
  }
}

const algoliaSearchPlugin: Plugin = (_context, inject) => {
  const options = JSON.parse('{"enabled":true,"appId":"IR8IZWC7D2","apiKey":"6d8bc5e3aa207e42542fce7ff393fd0c","indexName":"prodsupp_product","indexNameBiddable":"prodsupp_biddable","demoCategoriesEnabled":true,"demoCategoryUid":{"accessories":"","assortments":"","books":"","bundleMixedCase":"","events":"","glassware":"","other":"","spirits":"NDY2NDA=","wines":"MTMwODQ=","all":"MTMwODQ="}}') as AlgoliaSearchModuleOptions;
  const {
    enabled,
    appId,
    apiKey,
    indexName,
    indexNameBiddable,
    demoCategoryUid,
  } = options;

  const createClient = (customApiKey: string) => algoliasearch(appId, customApiKey);

  inject('algoliaSearch', {
    enabled,
    createClient,
    searchClient: createClient(apiKey),
    indexName,
    indexNameBiddable,
    demoCategoryUid,
  });
};

export default algoliaSearchPlugin;
