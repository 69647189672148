














import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { extractImage } from '@vsf-enterprise/amplience';
import { ImageInterface } from '@amplience/types';

export default defineComponent({
  name: 'Logo',
  props: {
    logo: {
      type: Object as PropType<ImageInterface>,
      default: () => ({}),
    },
  },
  setup(props) {
    const getImageUrl = (): string => {
      let image = { url: '/bbr/BBR-Logo.png' };

      if (props.logo) {
        image = extractImage(props.logo);
      }

      return image.url || '';
    };

    const getImageAlt = (): string => {
      let image = { alt: 'header-logo' };

      if (props.logo) {
        image = extractImage(props.logo);
      }

      return image.alt || '';
    };

    return {
      getImageUrl,
      getImageAlt,
    };
  },
});
